import {useState, useEffect} from "react";
import * as $ from "jquery";


const  App = () => {

  
  const [isRevealed, setRevealed] = useState(false);

  
  const toggleReveal = () =>{
    $('.lock').toggleClass('unlocked');
    $('.overlay').toggleClass('unblur')
    $("video").trigger("play");
    $("#audio")[0].play();
    $("#audio")[0].volume = .6;

    setTimeout(()=>{
      setRevealed(true);
    },300)

    
  }

  return (
    <div className='app-content'>
      
      <audio id="audio" style={{display : "none"}} loop>
        <source src="carti.mp3" type="audio/mpeg"></source>
        Your browser does not support the audio element.
      </audio>


      {
        isRevealed &&
        <>

      
        <div className='content'>
          
          <div className='sub-buttons'>
          <a className='magic-hover magic-hover__square' href='/catalog'>CATALOG</a><br/>
          <a target='_blank' rel='noreferrer' className='magic-hover magic-hover__square' href='https://unocompac.bigcartel.com/'>SHOP</a>
          </div>
          <a className='home side-logo' href='/#'><p >UNOCOMPAC</p></a>

          
          </div>




          <div className='socials'>
          
    <a target='_blank' rel='noreferrer' href='https://www.youtube.com/channel/UCiTUeggl2o1nsGHgJ3OT-jw/featured'><img  id='youtube'
    alt='youtube'
    src="youtube.png"/></a>
    <a target='_blank' rel='noreferrer' href='https://www.tiktok.com/@unocompac'><img  
    alt='tiktok'
    src="tiktok.png"/></a>
    <a target='_blank' rel='noreferrer' href='https://vsco.co/unocompac/gallery'><img id='vsco'
    alt='vsco'
    src="vsco.png"/></a>
    <a target='_blank' rel='noreferrer' href='https://www.grailed.com/unocompac'><img id='grailed'
    alt='grailed'
    src="grailed.png"/></a>
    <a target='_blank' rel='noreferrer' href='https://twitter.com/unocompac'><img id='twitter'
    alt='twitter'
    src="twitter.png"/></a>
    <a target='_blank' rel='noreferrer' href='http://instagram.com/unocompacmusic'><img id='instagram' 
    alt='instagram'
    src="https://img.icons8.com/material-outlined/2x/ffffff/instagram-new.png"/></a>
    <a target='_blank' rel='noreferrer' href='https://open.spotify.com/artist/5Ws6BCKMHD8nzv6JUdcgpF?si=lfWlI1QiRLmY2FJ4SP1MhA'><img id='spotify' 
    alt='spotify'
    src="https://img.icons8.com/metro/2x/ffffff/spotify.png"/></a>


<a target='_blank' rel='noreferrer' href='https://soundcloud.com/unocompacmusicc'><svg
id='soundcloud' 
alt='soundcloud'
 xmlns="http://www.w3.org/2000/svg" 
 aria-hidden="true" focusable="false" width="1em" 
 height="1em" 
 preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
   <path d="M11.56 8.87V17h8.76c1.85-.13 2.68-1.27 2.68-2.67c0-1.48-1.12-2.67-2.62-2.67c-.38 0-.7.08-1.03.22c-.24-2.34-2.23-4.17-4.68-4.17c-1.17 0-2.28.44-3.11 1.16m-.88 1.02c-.3-.18-.62-.32-.97-.39V17h1.39V9.34c-.15.16-.29.36-.42.55m-2.35-.54V17h.92V9.38c-.19-.03-.38-.04-.58-.04c-.12 0-.23 0-.34.01M6.5 10v7h.91V9.54c-.33.11-.64.27-.91.46m-1.67 2.5c-.06 0-.12-.06-.19-.09V17h.92v-6.14c-.37.48-.62 1.05-.73 1.64m-2.04-.28v4.69c.21.06.45.09.71.09h.22v-4.86c-.08-.01-.16-.02-.22-.02c-.26 0-.5.04-.71.1M1 14.56c0 .75.34 1.41.87 1.86v-3.71c-.53.44-.87 1.11-.87 1.85z" fill="#ffffff"/>
   <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
   </svg>
   </a>

    
    </div>

          </>
      }

      {

        !isRevealed &&
        <div className="lock-container">
          <span onClick={toggleReveal} className="lock"></span>
        </div>
      }


    <div className="fullscreen-video-wrap" dangerouslySetInnerHTML={{ __html: `
    <video class='overlay' playsinline loop autoplay muted>
    <source src="vid.mp4" type="video/mp4" />
    Your browser does not support the video tag. I suggest you upgrade your browser.
</video>            
` }}></div>

    </div>
    
  );
}

export default App;
