import "./Catalog.css";

const Catalog = () =>{

    return (
        <div className='catalog-container'>
            <div className='centered'>
                <br/>
                <br/>
            <a className='home side-logo' href='/#'><p >UNOCOMPAC</p></a>
            </div>
            
            <div className='items'>
                <img src='img_02.jpg'/>
                <img src='img_01.jpg'/>
                <img src='img_03.jpg'/>
                <img src='img_04.jpg'/>
                <img src='img_05.jpeg'/>
                <img src='img_06.jpeg'/>
                <img src='img_07.jpg'/>
                <img src='img_08.jpg'/>
                <img src='img_09.jpg'/>
                <img src='img_10.jpg'/>
                <img src='img_11.jpg'/>


            </div>
        </div>
    )
}

export default Catalog;